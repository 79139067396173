<template>
  <div>

    <div class="row">
      <div class="col-md-12">
        <Card :title="'Tambah Pasien'">
          <template v-slot:body>
            <div>

              <Form
                :form="form"
                :route="'patients'"
                purpose="master"
                :password='true'
              />

            </div>
          </template>
        </Card>
      </div>
    </div>

  </div>
</template>

<script>

import Card from "@/view/content/Card.vue"
import Form from '@/component/patients/Form.vue'
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module"

export default {

  name: 'Add',

  data() {
    return {
      form: {
        email: "",
        name: "",
        patient_id: "",
        birt_place: "",
        birt_date: "",
        address: "",
        gender: "",
        phone: "",
        mobile_phone: "",
        parent_name: "",
        blood_type: "",
        id_card_number: "",
        allergy: "",
        password: "",
        password_confirmation: "",
        status: "",
        title: "",
        doctor_in_charge: [],
        responsible_doctor: [],
        photo: [],
        photo_name: s3Endpoint + "/assets/public/default-profile.svg",
        photo_default: "/assets/public/default-profile.svg",
        notes:"",
        isResetImg: false,
        province_id:null,
        capital_id:null,
      }
    }
  },

  components: {
    Form,
    Card
  },

  methods: {

  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Pasien", route: "" },
      { title: "Daftar Pasien", route: "/patient/list" },
      { title: "Tambah" },
    ])
  },

}

</script>
